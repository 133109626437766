// ==========================================================================
// buttons
// ==========================================================================

.btn {
	padding-right: 22px;
	padding-left: 22px;
	border-radius: 1px;
	font-weight: bold;

	-webkit-transition: .1s linear;
	transition: .1s linear;

	> img {
		vertical-align: sub;
	}

	&.underconstruction,
	&.underconstruction:hover,
	&.underconstruction:focus,
	&.underconstruction:before,
	&.underconstruction:hover:before {
		// border-color: #e2e2e2 !important;
		// background: #e0e0e0 !important;
		// color: #fff !important;
		// cursor: default;
	}
}

%btn-base {
	@extend .btn;

	min-width: 235px;
	border-radius: 1px;
	// border-radius: $border-radius;
	color: #fff;
	font-weight: 400;
	text-shadow: 0 -1px 0 rgba(#000, .1);

	&[disabled] {
		background-color: #999;
		opacity: 1;
	}

	&:hover,
	&:focus {
		color: rgba(#fff, .8);
	}
}

.btn-fluid {
	width: 100% !important;
	min-width: 10px !important;
	padding-right: 0 !important;
	padding-left: 0 !important;
}


.btn-container-1 {
	margin: $gutter 0;
	text-align: center;

	@media screen and (min-width: $screen-sm) {
		margin: $gutter-md 0;
	}
}

.btn-container-2 {
	@extend .btn-container-1;
	@extend .separator-top-dotted;
	padding-top: $gutter;

	@media screen and (min-width: $screen-sm) {
		margin-top: $gutter !important;
	}
}

.btn-container-3 {
	@extend .btn-container-1;
	padding: $gutter * .5;
	background-color: rgba(253, 235, 192, .75);

	@media screen and (min-width: $screen-sm) {
		padding: $gutter;
	}
}

.btn-contact-container {
	display: inline-block;
	margin: 15px auto;
	padding: 15px;
	border: 1px solid #ddd;
	border-radius: $border-radius;
	background-color: #fff;

	@media screen and (min-width: $screen-sm) {
		.link-tel {
			margin: 0 40px;
			font-size: 28px;
		}
	}
}



.btn-extended {
	min-width: 200px;
	padding: 14px 20px;

	@media screen and (max-width: $screen-xs-max) {
		width: 100%;
		min-width: 10px;
		padding-right: 0;
		padding-left: 0;
	}

	@media screen and (min-width: $screen-sm) {
		padding: 18px 20px;
		max-width: initial;
		min-width: 340px !important;
		font-size: 16px;
	}
}

.btn-gradient-1 {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url(../img/common/gradient_1.png);
		background-repeat: no-repeat;
		background-position: 100% 0px;
		-webkit-background-size: 72px 30px;
		background-size: 72px 30px;
	}
}

.btn-1 {
	@extend .btn;
	@extend %base-transition;

	min-width: 180px;
	color: #fff;
	background-color: darken(c(1), 11%);
	border-color: darken(c(1), 12%);
	font-weight: 300;
	letter-spacing: 1px;
	// box-shadow: 
	// 	0 1px 1px rgba(#000, .2);

	// text-shadow: 0 1px 0 rgba(#fff, .5);

	// $gradient-start: lighten(c(1), 10%);
	// $gradient-end: c(1);
	// @include gradient-vertical($gradient-start, $gradient-end, 0%, 100%);


	&:hover,
	&:focus {
		// @include gradient-vertical($gradient-start, $gradient-end, 100%, 0);
		// box-shadow: 
		// 	0 1px 1px rgba(#000, .2),
		// 	inset 0 1px 0 rgba(#fff, .4),
		// 	inset 0 0 10px rgba(#fff, .2);
		background: lighten(c(1), 3%);
		color: lighten(c(3), 12%);
	}
}

.btn-1-extended {
	@extend .btn-1;
	@extend .btn-extended;
}


.btn-2 {
	@extend .btn-1;
	background-color: c(2);
	border-color: darken(c(2), 2%);
	color: #fff;
	text-shadow: 0 -1px 0 rgba(#000, .5);

	// box-shadow: 
	// 	0 1px 1px rgba(#000, .2),
	// 	inset 0 1px 0 rgba(#fff, .4),
	// 	inset 0 0 10px rgba(#000, .3);

	// $gradient-start: lighten(c(2), 10%);
	// $gradient-end: c(2);
	// @include gradient-vertical($gradient-start, $gradient-end, 0%, 100%);

	&:hover,
	&:focus {
		border-color: lighten(c(2), 11%);
		background: lighten(c(2), 12%);
		color: #fff;
	}
}

.btn-2-extended {
	@extend .btn-2;
	@extend .btn-extended;
}


.btn-3 {
	@extend .btn-1;
	background-color: darken(c(3), 11%);
	border-color: darken(c(3), 12%);

	&:hover,
	&:focus {
		background: lighten(c(3), 8%);
		color: #fff;
	}
}

.btn-3-extended {
	@extend .btn-3;
	@extend .btn-extended;
}


.btn-4 {
	@extend .btn;
	border: 1px solid $color-border-1;
	color: $color-font;

	&:hover,
	&:focus {
		color: #888;
		background-color: #f2f2f2;
		border-color: darken($color-border-1, 6%);
	}
}

.btn-5 {
	@extend .btn;
	padding: 12px 22px;
	border-radius: 0;
	background-color: #f2f2f2;
	color: #333;
}


.btn-6 {
	@extend .btn-1;
	background-color: c(6);
	border-color: darken(c(6), 1%);
	color: #fff;

	// box-shadow: 
	// 	0 1px 1px rgba(#000, .2),
	// 	inset 0 1px 0 rgba(#fff, .4),
	// 	inset 0 0 10px rgba(#000, .3);

	// $gradient-start: lighten(c(6), 10%);
	// $gradient-end: c(6);
	// @include gradient-vertical($gradient-start, $gradient-end, 0%, 100%);

	&:hover,
	&:focus {
		border-color: lighten(c(6), 11%);
		background: lighten(c(6), 12%);
		color: #fff;
	}
}

.btn-6-extended {
	@extend .btn-6;
	@extend .btn-extended;
}

.btn-7 {
	@extend .btn-1;
	background-color: c(7);
	border-color: darken(c(7), 1%);
	color: #fff;

	// box-shadow: 
	// 	0 1px 1px rgba(#000, .2),
	// 	inset 0 1px 0 rgba(#fff, .4),
	// 	inset 0 0 10px rgba(#000, .3);

	// $gradient-start: lighten(c(7), 10%);
	// $gradient-end: c(7);
	// @include gradient-vertical($gradient-start, $gradient-end, 0%, 100%);

	&:hover,
	&:focus {
		border-color: lighten(c(7), 11%);
		background: lighten(c(7), 12%);
		color: #fff;
	}
}

.btn-7-extended {
	@extend .btn-7;
	@extend .btn-extended;
}


.btn-99 {
	@extend %btn-base;
	padding: 5px 0;
	background-color: #e3e3e3;
	border-color: #e2e2e2;
	line-height: 2.5;
	color: $color-font;

	&:hover,
	&:focus {
		border-color: #eee;
		background-color: #f2f2f2;
		color: #aaa;
	}
}
.btn-100-extended {
	@extend .btn-100;
	@extend .btn-extended;
}

.btn-100 {
	@extend %btn-base;
	padding: 5px 0;
	background-color: #fff;
	border-color: #777;
	line-height: 2.5;
	color: $color-font;

	&:hover,
	&:focus {
		border-color: #eee;
		background-color: #f2f2f2;
		color: #aaa;
	}
}
.btn-100-extended {
	@extend .btn-100;
	@extend .btn-extended;
}

.btn-101 {
	@extend %btn-base;
	background-color: #fafafa;
	border-color: #999;
	color: $color-font;
	text-shadow: 0 1px 0 #fff;
	box-shadow: 
		0 1px 1px rgba(#000, .2),
		inset 0 1px 0 #fff,
		inset 0 0 10px rgba(#000, .1);

	@include gradient-vertical(#fff, #e0e0e0, 0%, 100%);

	&:hover,
	&:focus {
		color: $color-font;
		background: #fafafa;
	}
}
.btn-101-extended {
	@extend .btn-101;
	@extend .btn-extended;
}


.btn-block-1 {
	@extend %base-transition;

	display: block;
	overflow: hidden;
	margin-bottom: $gutter;
	color: $color-font;

	@media screen and (min-width: $screen-md) {
		padding: 6px;
	}

	.img-pull-left {
		margin-bottom: 0;
	}

	&:hover {
		text-decoration: none;
		color: #888;
		background-color: #f2f2f2;
	}
}


.btn-accordion-trigger {
	position: relative;

	.accordion-caret {
		position: absolute;
		top: 50%;
		right: 5%;
		transition: all .2s ease-in-out;
		transform: translate(0, -50%);
		@extend %base-caret;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 8px solid;;
	}

	&.collapsed .accordion-caret {
		transform: translate(0, -50%) rotate(-180deg);
	}
}

.btn-img-1 {
	img {
		@extend .img-hover;
		margin-right: auto;
		margin-left: auto;
	}
}


.btn-twitter {
	@extend .btn;
	@extend .btn-extended;
	color: #fff;
	background-color: $color-twitter;
	border-color: darken($color-twitter, 5%);

	&:hover,
	&:focus {
		color: #fff;
		background-color: lighten($color-twitter, 4%);
	}
}

%btn-sns {
	@extend .btn;
	padding: 4px 15px;
	border-radius: 0;
	color: #333;
	font-size: 12px;
	font-weight: normal;

	&:hover {
		// color: rgba(#fff, .6);
	}
}

%btn-sns-rounded {
	overflow: hidden;
	display: block;
	line-height: 1.9;
	width: 25px;
	height: 25px;
	border-radius: 100%;
	color: #fff;
	font-weight: bold;
	text-align: center;
}

.btn-sns-twitter {
	@extend %btn-sns;
	background-color: #f2f2f2;
	// background-color: $color-twitter;
	
	&:hover {
		border-radius: 3px;
		border-color: #eee;
		background-color: transparent;
		// background-color: lighten($color-twitter, 10%);
	}
}

.btn-sns-facebook {
	@extend %btn-sns;
	background-color: #f2f2f2;
	// background-color: $color-facebook;
	
	&:hover {
		border-radius: 3px;
		border-color: #eee;
		background-color: transparent;
		// background-color: lighten($color-facebook, 10%);
	}
}

.btn-sns-instagram {
	@extend %btn-sns;
	background-color: #f2f2f2;
	// background-color: $color-instagram;
	
	&:hover {
		border-radius: 3px;
		border-color: #eee;
		background-color: transparent;
		// background-color: lighten($color-instagram, 10%);
	}
}



// btn-group
// ==========================================================================

.btn-group-1 {
	@extend .btn-group;

	@media screen and (max-width: $screen-xs-max) {
		.btn {
			width: 100%;
			border-radius: 0;

			& + .btn {
				margin-top: -1px;
				margin-left: 0;
			}
		}
	}

	@media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) {
		.btn-extended {
			min-width: initial !important;
		}
	}

	@media screen and (min-width: $screen-md) {
		> .btn-extended {
			min-width: 240px !important;
		}
	}
}

.btn-group-justified {
	@media screen and (max-width: $screen-xs-max) {
		display: block;

		.btn {
			display: block;
		}
	}

	@media screen and (min-width: $screen-sm) {
		.btn {
			border-right-width: 0;

			&:last-of-type {
				border-right-width: 1px;
			}
		}
	}
}
