// ==========================================================================
// boxes
// ==========================================================================

.box {
	@extend .g-b;
	border: 1px solid transparent;

	> p:last-child,
	> [itemprop="text"] > p:last-child {
		margin-bottom: 0;
	}
}

.box-fullsize-container {
	margin-left: $grid-gutter-width * -.5;
	margin-right: $grid-gutter-width * -.5;
}

@media screen and (max-width: $screen-xs-max) {
	.box-xs-fullsize-container {
		margin-left: $grid-gutter-width * -.5;
		margin-right: $grid-gutter-width * -.5;
	}
}
@media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) {
	.box-sm-fullsize-container {
		margin-left: $grid-gutter-width * -.5;
		margin-right: $grid-gutter-width * -.5;
	}
}
@media screen and (min-width: $screen-md) and (max-width: $screen-md-max) {
	.box-md-fullsize-container {
		margin-left: $grid-gutter-width * -.5;
		margin-right: $grid-gutter-width * -.5;
	}
}
@media screen and (min-width: $screen-lg) {
	.box-lg-fullsize-container {
		margin-left: $grid-gutter-width * -.5;
		margin-right: $grid-gutter-width * -.5;
	}
}


.box-bordered {
	border: 1px solid $color-border-1;
}

.box-rounded {
	border-radius: $border-radius; 
}

.box-body {
	padding: 8%;

	> p:last-child {
		margin-bottom: 0;
	}
}

.box-body-condenced {
	padding: $gutter * .5;
}

.box-inner {
	@extend .box-body;
	margin: 10px;
	background: #fff;
}

.box-shadow-1 {
	box-shadow: 0 1px 5px rgba(#000, .1);
}
.box-shadow-2 {
	box-shadow: 0 2px 0 rgba(#000, .2);
}
.box-shadow-3 {
	box-shadow: 1px 1px 4px rgba(#000, .2);
}
.box-shadow-4 {
	box-shadow: 0 0 12px rgba(#000, .4);
	background-color: #fff;
}


.box-0 {
	@extend .box;
	// @extend .box-rounded;
	background-color: $color-bg-0;
}

@for $i from 1 through length($colors) {
	.box-#{$i} {
		@extend .box;
		// @extend .box-rounded;
		background-color: c($i);
	}
}

.box-reservation-container {
	padding-top: 50px;
	padding-bottom: 50px;
	border-top: 1px solid gs(14);
	background-color: c(4);
}

.box-text-1 {
	@extend .box;

	@media screen and (min-width: $screen-sm) {
		text-align: center;
	}
}


.box-img-1 {
	@extend .box;

	> a > img,
	> img {
		margin-bottom: 10px;

		@media screen and (min-width: $screen-sm) {
			margin-bottom: $gutter;
		}
	}
}

.box-img-title-1 {
	@extend .text-1;
	text-align: center;
	font-size: 16px;
	font-weight: bold;
}


.box-table {
	@extend .box;
	display: table;
	width: 100%;
}

.box-table-row {
	display: table-row;
}

.box-table-cell {
	display: table-cell;
	vertical-align: middle;
}

.box-table-cell-w-50p {
	@extend .box-table-cell;
	width: 50%;
}


.box-googlemaps {
	width: 100%;
	height: 480px;
	padding: 3px;
	border: 1px solid #cfc6b2;
	background-color: #fff;

	.map_canvas {
		width: 100% !important;
		height: 100% !important;
		border: none !important;
	}

	@media screen and (max-width: $screen-xs-max) {
		height: 280px;
	}
}

.box-grid-lineup {
	margin-bottom: 40px;

	@media screen and (min-width: $screen-sm) {
		display: grid;
		grid-template-columns: 40% 1fr;
		grid-template-rows: 1fr auto;
		grid-template-areas:
			"image texts"
			"image price";
		grid-gap: 10px 30px;
		margin-bottom: 60px;
	}

	@media screen and (min-width: $screen-md) {
		grid-gap: 10px 60px;
	}

	@media screen and (min-width: $screen-lg) {
		grid-gap: 10px 90px;
		margin-bottom: 90px;
	}
}

.box-grid-lineup-image { grid-area: image; }
.box-grid-lineup-texts { grid-area: texts; }
.box-grid-lineup-price { grid-area: price; }

.box-grid-lineup-image {
	margin-bottom: 0;

	.slick-slider {
		margin-bottom: 0;
	}

	.slick-dots {
		bottom: 10px;
		margin-bottom: 0;
	}

	@media screen and (max-width: $screen-xs-max) {
		margin-bottom: 30px;
	}
}

.box-grid-lineup-texts {
	> p {
		line-height: 1.8;

		&:first-child {
			b {
				font-size: 17px;
			}
		}
	}
}

.box-grid-lineup-price {
	table {
		margin-bottom: 0;
		td {
			text-align: right;
		}
	}

	@media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) {
		th, td {
			padding-top: 16px;
			padding-bottom: 16px;
		}
	}
}

.box-accordion-1 {
	.accordion-toggle {
		margin: 0;
		line-height: 1.5;
		font-size: 16px;
		font-weight: normal;

		a {
			// @extend .clearfix;
			// @extend .icon-arrow-down-1;
			position: relative;
			display: block;
			padding-right: 32px;

			&::after {
				content: '';
				position: absolute;
				top: 50%;
				right: 10px;
				transition: .23s;
				transform: translateY(-50%) rotate(540deg);
				width: 24px;
				height: 24px;
				background: url(#{$dir}/img/common/icon_arrow_down_1.png) no-repeat;
				background-size: contain;
			}

			.image {
				transition: .26s;
				width: 0;
			}

			.texts {
				transition: .3s;
				width: 97%;
			}

			&.collapsed {
				.image {
					width: 24%;
				}

				.texts {
					width: 70%;
				}

				&::after {
					transform: translateY(-50%);
				}
			}

			&:active,
			&:hover,
			&:focus {
				text-decoration: none;
			}

			.img-icon-1 {
				float: left;
				margin-right: 8px;
			}
		}

		&-text {
			display: inline-block;
			margin-top: 4px;
			color: #555;
		}

		&-text-small {
			font-family: $font-family-serif-base;
			font-style: italic;
			font-size: 12px;
			float: right;
			margin-top: 12px;
		}
	}

	.panel {
		border-radius: 0;
	}

	.panel-heading {
		padding: 7px;
	}

	.panel-body {
		p {
			margin-bottom: 15px;
		}
	}

	@media screen and (max-width: 375px) {
		h3 {
			small {
				display: none;
			}
		}
	}
}

.box-accordion-2 {
	@extend .box-accordion-1;

	.panel {
		border-color: #e2d4d4;
		& + .panel {
			margin-top: -1px;
		}
	}

	.panel-default {
		> .panel-heading {
			border-color: #e2d4d4;
			background-color: #fdefef;

			& + .panel-collapse {
				> .panel-body {
					border-color: #e2d4d4;
				}
			}
		}

		&:nth-child(odd) {
			> .panel-heading {
				background-color: #fffafa;
			}
		}
	}
}


.box-accordion-show-grid-pc {
	.title-cloned {
		display: none;
	}

	@media screen and (min-width: $screen-sm) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		> .panel {
			flex-basis: 31%;
			margin-bottom: 40px;
		}

		.accordion-toggle {
			display: none;
		}

		.panel-body {
			padding: 20px 25px;
			border-top: none;
		}

		.image {
			margin-left: -26px;
			margin-right: -26px;
		}

		.title-cloned {
			display: block;
			margin-bottom: 15px;

			span {
				> :first-child {
					font-weight: bold;
					font-size: 16px;
				}
			}
		}
	}
}

