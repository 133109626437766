// ==========================================================================
// header
// ==========================================================================

$sp-header-banners-height: 29px;
// $sp-header-banners-height: 31px;
$header-height: 48px;
$header-md-height: 86px;

@mixin header-transition($duration) {
	-webkit-transition: all $duration ease-in-out;
	-moz-transition: all $duration ease-in-out;
	-ms-transition: all $duration ease-in-out;
	-o-transition: all $duration ease-in-out;
	transition: all $duration ease-in-out;
}

.header-alternation-fixed {
	height: $sp-header-banners-height + $header-height;

	@media screen and (min-width: $screen-md) {
		height: $header-md-height;
	}
}

.site-header {
	@include header-transition(.3s);
}
.header-logo {
	@include header-transition(.2s);
}
.global-nav {
	@include header-transition(.4s);
}

@media screen and (min-width: $screen-md) {
	.contents-fixed {
		.site-header {
			height: 62px;
		}
		.header-logo {
			// top: 20px;
			width: $logo-width * .75;
		}
		.global-nav {
			top: 15px;
		}
	}
}

.site-header {
	position: fixed;
	z-index: 99;
	width: 100%;
	height: $header-height;
	border-bottom: 1px solid darken(gs(16), 10%);
	background-color: rgba(255, 255, 255, 1);

	@media screen and (min-width: $screen-md) {
		height: $header-md-height;
	}
}

.header-inner {
	@extend .container;
	position: relative;
	height: 100%;

	@media screen and (min-width: $screen-lg) {
		width: 100%;
		max-width: $content-max-width + $grid-gutter-width;
	}
}

.header-logo {
	$width: $logo-width;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateY(-46%);
	width: $width * .66;
	margin-left: $width * -.25;
	line-height: 1.0;
	font-size: 12px;

	@media screen and (min-width: $screen-md) {
		// top: 27px;
		left: $grid-gutter-width * .5;
		width: $width;
		margin-left: 0;
	}

	a {
		display: inline-block;
		
		&:hover {
			text-decoration: none;
		}
	}

	img {
		@extend .img-responsive;
	}
}

.sp-header-tel {
	position: absolute;
	z-index: 98;
	top: 11px;
	left: $grid-gutter-width * .5;

	@extend .hidden-md;
	@extend .hidden-lg;
}

.sp-header-link {
	position: fixed;
	z-index: 99;
	top: 15px;
	right: $grid-gutter-width * .5;

	@extend .hidden-md;
	@extend .hidden-lg;
}

.sp-header-banners {
	$bc: darken(c(6), 3%);
	position: absolute;
	top: $header-height;
	left: 0;
	width: 100%;
	margin: 0;
	// border-top: 1px solid $bc;
	// border-bottom: 1px solid $bc;

	@extend .hidden-md;
	@extend .hidden-lg;

	a {
		float: left;
		width: 50%;
		min-width: auto;
		font-size: 12px;
		line-height: 1;
		padding: 9px 0;
		border: none;
		border-radius: 0;

		& + a {
			border-left: 1px solid $bc;
		}
	}
}