.link-tel {
	color: $color-font;
}

.link-color-font {
	@extend %base-transition;
	color: $color-font;
	&:hover {
		color: $color-hover;
		text-decoration: none;
	}
}

.links-color-font {
	a {
		@extend %base-transition;
		color: $color-font;
		&:hover {
			color: $color-hover;
			text-decoration: none;
		}
	}
}

.link-color-font-fff {
	@extend %base-transition;
	color: #fff;
	&:hover {
		opacity: .7;
		text-decoration: underline;
	}
}

.links-color-font-fff {
	a {
		@extend %base-transition;
		color: #fff;
		&:hover {
			opacity: .7;
			text-decoration: underline;
		}
	}
}


.links-1 {
	@extend .list-unstyled;

	li {
		margin: 10px;
	}

	a {
		display: block;
		padding: 10px 10px;
		background-color: #fff;
	}
}

.link-box-1 {
	display: block;
	line-height: 1.2;
	text-decoration: none;
	color: c(2);
	@extend %base-transition;

	&:hover {
		text-decoration: none;
		color: #999;
	}

	&-text-2 {
		display: inline-block;
		margin-top: 7px;
		padding: 5px 20px;
		border: 1px solid rgba(c(2), .5);
		font-size: 13px;
	}
}

.link-box-2 {
	@extend .link-box-1;
	@extend .link-h-shadow;
}

.link-h-shadow {
	@extend %base-transition;

	&:hover {
		box-shadow: 0 2px 16px rgba(0, 0, 0, .3);
	} 
}
