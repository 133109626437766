// ==========================================================================
// tables
// ==========================================================================

th, td {
	> ol:last-child,
	> ul:last-child {
		margin-bottom: 0;
	}
}

.table-1 {
	$table-border-style: dotted;
	$table-border-color: #a59d9d;
	@extend .table;
	border-bottom: 1px $table-border-style $table-border-color;

	> thead,
	> tbody,
	> tfoot {
		> tr {
			> th,
			> td {
				padding: 5px 5px 5px 0;
				vertical-align: middle;
				border-top: 1px $table-border-style $table-border-color;
			}

			> th {
				white-space: nowrap;
				color: $color-brand-2;
			}
		}
	}
}

.table-1-extended {
	@extend .table-1;

	> thead,
	> tbody,
	> tfoot {
		> tr {
			> th,
			> td {
				padding: 15px 5px;
			}
		}
	}
}

.table-2 {
	$table-border-style: solid;
	$table-border-color: #d4d4d4;
	@extend .table;
	border-bottom: 1px $table-border-style $table-border-color;

	> thead,
	> tbody,
	> tfoot {
		> tr {
			> th,
			> td {
				padding: 24px 10px;
				vertical-align: middle;
				border-top: 1px $table-border-style $table-border-color;
			}

			> th {
				white-space: nowrap;
			}
		}
	}
}

$table-3-border-color: #d8cfcd;

.table-3 {
	$table-border-style: solid;
	$table-border-color: $table-3-border-color;
	@extend .table;
	border: 1px $table-border-style $table-border-color;

	> thead,
	> tbody,
	> tfoot {
		> tr {
			> th,
			> td {
				padding: 12px 10px;
				vertical-align: middle;
				border: 1px $table-border-style $table-border-color;
			}

			> th {
				white-space: nowrap;
				background-color: #f9f8f7;
				color: #823e35;
			}
		}
	}
}


@media (max-width: $screen-xs-max) {
	.table-responsive {
		display: block;
		border-top: none !important;
		border-right: none !important;
		border-left: none !important;
		thead, tbody, tr, th, td {
			display: block;
			border-bottom: none !important;
		}

		> thead,
		> tbody,
		> tfoot {
			> tr {
				> th,
				> td {
				}

				> td {
					padding-top: 0;
					border-top: none;
					background-color: #fff;
				}
			}
		}
	}

	.table-3.table-responsive {
		> thead,
		> tbody,
		> tfoot {
			> tr {
				> td {
					padding-top: 12px;
					border-top: 1px solid $table-3-border-color;
				}
			}
		}
	}

	.table-overfow-container {
		overflow-x: scroll;
		width: 100%;
		margin-bottom: $gutter;

		> table {
			margin-bottom: 0;
			th, td {
				padding: 6px 4px !important;
				font-size: 12px;
			}

			th {
				white-space: nowrap;
			}
		}
	}
}

@for $i from 1 through 20 {
	$n: $i * 10;
	.table-td-miw-#{$n} {
		td { min-width: #{$n}px;}
	}
}