@charset "UTF-8";

.modal-campaign {
	.modal-btn-close {
		@include closs(#000, 50px);
		@extend %base-transition;
		position: absolute;
		top: 20px;
		right: 20px;
		// opacity: 1;

		&:hover {
			opacity: 1;
		}

		@media screen and (max-width: $screen-xs-max) {
			top: 0;
			right: 0;
			width: 30px;
			height: 30px;
		}
	}
}

.modal-dialog {
	max-width: 1140px;

	@media screen and (min-width: $screen-sm) {
		width: 90%;
	}
}

.modal-content {
	border-radius: 0;

	.box-campaign-1 {
		margin-left: 6%;
		margin-right: 6%;
		margin-bottom: 8%;
	}
}
