@charset "UTF-8";

@import
"variables",

// bootstrap
"../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap",

"./lib/mixins",

"elements",
"header",
"footer",
// "footer-links",
"menu-trigger",
"global-nav",
// "container",

// "common",
"forms",
"boxes",
"texts",
"navs",
"links",
// "lists",
"borders",
"tables",

"buttons",
"separator",
"backgrounds",
"simplelightbox",
"grids",
"slides",
"modals",
"helpers",

"./lib/gutters",
"./lib/sizes",
"./lib/texts",

"images",
"photoswipe",

"./pages/blogs",
"./pages/home";


/**
 * 一時的にフッターのMenuを非表示
 * グローバルナビは common.js で
 */
.footer-links > ul > li:nth-child(2) > a {
	display: none;
}
