@charset "UTF-8";

.sl-overlay {
	background: #000;
	opacity: .8;
}

.sl-wrapper {
	button {
		color: #aaa;
	}
}