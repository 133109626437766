@charset "UTF-8";

@media screen and (min-width: $screen-md) {
	/* -------------------------------------------------
	IPA Font License Agreement v1.0
	http://ipafont.ipa.go.jp/ipa_font_license_v1.html
	------------------------------------------------- */
	@font-face {
		font-family: IPAex明朝;
		src: url('https://cdn.leafscape.be/IPAexfont/ipaexm_web.woff2')
		format("woff2");
	}
}

// ==========================================================================
// text
// ==========================================================================

.text-sans {
	font-family: $font-family-base;
}

.text-serif {
	font-family: $font-family-serif-base;
	
	// @media screen and (min-width: $screen-md) {
		// font-family: "IPAex明朝", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
		// -webkit-text-stroke: 0.2px;
	// }
}

.text-font-1 {
	font-family: "Open Sans", sans-serif;
}

.text-font-2 {
	font-family: "Cinzel Decorative", sans-serif;
}


.text-fff { color: #fff !important;}
.text-0 { color: $color-font !important;}
.text-24 { color: #ff757f !important;}

@for $i from 1 through length($colors) {
	.text-#{$i} {
		color: c($i) !important;
	}
}

.text-shadow-1 { text-shadow: 0 0 10px rgba(0, 0, 0, .8); }
.text-shadow-2 { text-shadow: 0 0 4px #fff, 0 0 15px #fff; }
.text-shadow-0 { text-shadow: none !important;}

.text-blog-date {
	color: #999;
}


.text-indent-1 {
	text-indent: -1em;
	padding-left: 1em;
}
.text-indent-1_5 {
	text-indent: -1.5em;
	padding-left: 1.5em;
}
.text-indent-2 {
	text-indent: -2em;
	padding-left: 2em;
}

.text-italic {
	font-style: italic;
}


.text-right { text-align: right !important;}
.text-left  { text-align: left !important;}
.text-center{ text-align: center !important;}

.text-lh-0   { line-height: 0 !important; }
.text-lh-1_0 { line-height: 1.0 !important; }
.text-lh-1_1 { line-height: 1.1 !important; }
.text-lh-1_2 { line-height: 1.2 !important; }
.text-lh-1_3 { line-height: 1.3 !important; }
.text-lh-1_4 { line-height: 1.4 !important; }
.text-lh-1_5 { line-height: 1.5 !important; }
.text-lh-1_6 { line-height: 1.6 !important; }
.text-lh-1_7 { line-height: 1.7 !important; }
.text-lh-1_8 { line-height: 1.8 !important; }
.text-lh-1_9 { line-height: 1.9 !important; }
.text-lh-2_0 { line-height: 2.0 !important; }

@media screen and (max-width: $screen-xs-max) {
	.text-xs-right { text-align: right !important;}
	.text-xs-left  { text-align: left !important;}
	.text-xs-center{ text-align: center !important;}

	.text-xs-lh-0   { line-height: 0 !important; }
	.text-xs-lh-1_0 { line-height: 1.0 !important; }
	.text-xs-lh-1_1 { line-height: 1.1 !important; }
	.text-xs-lh-1_2 { line-height: 1.2 !important; }
	.text-xs-lh-1_3 { line-height: 1.3 !important; }
	.text-xs-lh-1_4 { line-height: 1.4 !important; }
	.text-xs-lh-1_5 { line-height: 1.5 !important; }
	.text-xs-lh-1_6 { line-height: 1.6 !important; }
	.text-xs-lh-1_7 { line-height: 1.7 !important; }
	.text-xs-lh-1_8 { line-height: 1.8 !important; }
	.text-xs-lh-1_9 { line-height: 1.9 !important; }
	.text-xs-lh-2_0 { line-height: 2.0 !important; }

	.text-xs-translate-0 {
		-webkit-transform: translate(0, 0) !important;
		-ms-transform: translate(0, 0) !important;
		-o-transform: translate(0, 0) !important;
		transform: translate(0, 0) !important;
	}
}

@media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) {
	.text-sm-right { text-align: right !important;}
	.text-sm-left  { text-align: left !important;}
	.text-sm-center{ text-align: center !important;}

	.text-sm-lh-0   { line-height: 0 !important; }
	.text-sm-lh-1_0 { line-height: 1.0 !important; }
	.text-sm-lh-1_1 { line-height: 1.1 !important; }
	.text-sm-lh-1_2 { line-height: 1.2 !important; }
	.text-sm-lh-1_3 { line-height: 1.3 !important; }
	.text-sm-lh-1_4 { line-height: 1.4 !important; }
	.text-sm-lh-1_5 { line-height: 1.5 !important; }
	.text-sm-lh-1_6 { line-height: 1.6 !important; }
	.text-sm-lh-1_7 { line-height: 1.7 !important; }
	.text-sm-lh-1_8 { line-height: 1.8 !important; }
	.text-sm-lh-1_9 { line-height: 1.9 !important; }
	.text-sm-lh-2_0 { line-height: 2.0 !important; }
}

@media screen and (min-width: $screen-md) and (max-width: $screen-md-max) {
	.text-md-right { text-align: right !important;}
	.text-md-left  { text-align: left !important;}
	.text-md-center{ text-align: center !important;}

	.text-md-lh-0   { line-height: 0 !important; }
	.text-md-lh-1_0 { line-height: 1.0 !important; }
	.text-md-lh-1_1 { line-height: 1.1 !important; }
	.text-md-lh-1_2 { line-height: 1.2 !important; }
	.text-md-lh-1_3 { line-height: 1.3 !important; }
	.text-md-lh-1_4 { line-height: 1.4 !important; }
	.text-md-lh-1_5 { line-height: 1.5 !important; }
	.text-md-lh-1_6 { line-height: 1.6 !important; }
	.text-md-lh-1_7 { line-height: 1.7 !important; }
	.text-md-lh-1_8 { line-height: 1.8 !important; }
	.text-md-lh-1_9 { line-height: 1.9 !important; }
	.text-md-lh-2_0 { line-height: 2.0 !important; }
}

@media screen and (min-width: $screen-lg) {
	.text-lg-right { text-align: right !important;}
	.text-lg-left  { text-align: left !important;}
	.text-lg-center{ text-align: center !important;}

	.text-lg-lh-0   { line-height: 0 !important; }
	.text-lg-lh-1_0 { line-height: 1.0 !important; }
	.text-lg-lh-1_1 { line-height: 1.1 !important; }
	.text-lg-lh-1_2 { line-height: 1.2 !important; }
	.text-lg-lh-1_3 { line-height: 1.3 !important; }
	.text-lg-lh-1_4 { line-height: 1.4 !important; }
	.text-lg-lh-1_5 { line-height: 1.5 !important; }
	.text-lg-lh-1_6 { line-height: 1.6 !important; }
	.text-lg-lh-1_7 { line-height: 1.7 !important; }
	.text-lg-lh-1_8 { line-height: 1.8 !important; }
	.text-lg-lh-1_9 { line-height: 1.9 !important; }
	.text-lg-lh-2_0 { line-height: 2.0 !important; }
}

.text-uppercase { text-transform: uppercase !important; }
.text-lowercase { text-transform: lowercase !important; }
.text-capitalize { text-transform: capitalize !important; }

.text-underlined {
	line-height: 2.0;
	display: block;
	border-bottom: 1px dotted #aaa;
}

.text-cite {
	font-size: 10px;
	color: #999;

	> a {
		color: #999;
	}
}

.text-tel {
	vertical-align: middle;
	margin-right: 8px;
	padding-left: 20px;
	background: url(../img/common/icon_phone.png) no-repeat 0 50%;
	-webkit-background-size: 12px 20px;
	background-size: 12px 20px;
	font-size: 21px;
}

.text-img-center {
	position: relative;

	.text {
		@extend .text-shadow-2;
		position: absolute;
		top: 50%;
		left: 50%;
		text-align: center;
		transform: translateX(-50%) translateY(-50%);
	}

	.text-square {
		width: 82%;
		padding: 12% 0;
		background-color: rgba(#fff, .6);
	}
}

.text-img-bottom {
	position: relative;
	
	.text {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 6px 3px;
		background-color: rgba(c(1), .7);
		color: #fff;
	}
}

// titles
// ==========================================================================

%title {
	font-weight: normal;
}

.title-1 {
	@extend %title;
	line-height: 1.25;
	margin: $gutter 0 $gutter * .75;
	font-size: 21px;
	text-align: center;
	color: c(1);

	// @extend .text-serif;

	@media screen and (min-width: $screen-sm) {
		font-size: 24px;
	}

	@media screen and (min-width: $screen-md) {
		font-size: 28px;
	}
}

.title-1-en {
	@extend .title-1;
	font-size: 28px;

	@media screen and (min-width: $screen-sm) {
		font-size: 31px;
	}

	@media screen and (min-width: $screen-md) {
		font-size: 34px;
	}
}

.title-2 {
	@extend %title;
	@extend .text-serif;
	line-height: 1.25;
	margin: 42px 0 36px;
	font-size: 22px;
	color: c(1);
	text-align: center;

	small {
		display: block;
		margin-top: 8px;
		font-size: 1.5rem;
	}

	@media screen and (min-width: $screen-sm) {
		margin: 57px 0 50px;
		font-size: 28px;
	}

	@media screen and (min-width: $screen-md) {
		margin: 90px 0 80px;
		font-size: 32px;
	}
}

.title-2-en {
	@extend .title-2;
	font-size: 32px;

	@media screen and (min-width: $screen-sm) {
		font-size: 36px;
	}

	@media screen and (min-width: $screen-md) {
		font-size: 42px;
	}
}

.title-3 {
	@extend %title;
	line-height: 1.3;
	margin: $gutter * .75 0;
	padding: 15px 2px 13px;
	border-top: 1px solid;
	border-bottom: 1px solid;
	font-size: 18px;

	@media screen and (min-width: $screen-sm) {
		margin: $gutter 0;
	}
}

.title-4 {
	@extend %title;
	line-height: 1.3;
	margin: $gutter 0 $gutter * .5;
	padding: 15px 2px 13px;
	border-top: 2px solid;
	border-bottom: 1px dotted #aaa;
	font-size: 18px;
	font-weight: bold;
	color: $color-main;
}

.title-5 {
	@extend %title;
	font-size: 18px;
	text-align: center;

	@media screen and (min-width: $screen-sm) {
		margin: $gutter 0;
	}
}

.title-6 {
	@extend %title;
	@extend .text-serif;
	@extend .fz-xs-21;
	margin: $gutter 0;
	color: $color-extra;
	font-size: 24px;
	font-weight: bold;
	text-align: center;
}

.title-7 {
	@extend %title;
	margin-bottom: ($gutter - 2px) * .75;
	font-size: 18px;

	@media screen and (min-width: $screen-sm) {
		margin-bottom: ($gutter-md - 4px) * .75;
		font-size: 20px;
	}

	@media screen and (min-width: $screen-sm) {
		margin-bottom: $gutter-md;
		font-size: 21px;
	}
}

.title-8 {
	@extend %title;
	margin-bottom: ($gutter - 2px) * .75;
	padding-top: $gutter * .75;
	border-top: 1px dotted rgba(0, 0, 0, 0.21);
	font-size: 18px;

	@media screen and (min-width: $screen-sm) {
		margin-bottom: ($gutter-md - 4px) * .75;
		padding-top: $gutter-md * .75;
		font-size: 20px;
	}

	@media screen and (min-width: $screen-sm) {
		margin-bottom: $gutter-md;
		padding-top: $gutter-md;
		font-size: 21px;
	}
}


.title-course {
	@extend .container;
	position: relative;
	margin-bottom: $gutter;
	text-align: center;

	.title {
		@extend %title;
		@extend .text-serif;
		line-height: 1.25;
		margin: ($gutter + 7px) 0 $gutter;
		font-feature-settings: "palt";
		font-size: 31px;
		color: c(1);
		text-align: center;

		small {
			display: block;
			margin-top: 8px;
			font-size: 13px;
		}

		@media screen and (min-width: $screen-sm) {
			font-size: 38px;
		}

		@media screen and (min-width: $screen-md) {
			margin: $gutter-md 0 $gutter-md * .5;
			font-size: 42px;
		}
	}

	.price {
		max-width: 320px;
		margin: 0 auto 10px;
		padding: 7px;
		border: 1px solid #b2b2b2;
		color: c(2);
	}

	.lead {
		@extend .text-serif;
		font-size: 20px;
		line-height: 1.8;
		// writing-mode: vertical-rl;
		// position: absolute;
		// top: 50%;
		// left: 8%;
		// transform: translate(0, -50%);
		// white-space: nowrap;

		@media screen and (min-width: $screen-md) {
			margin-top: 40px;
		}
	}

	.campaign-badge {
		position: absolute;
		// top: 50%;
		top: 0;
		right: 2%;
		transform: translate(0, -70%);

		a {
			@extend %base-transition;
			display: block;
			filter: drop-shadow(2px 3px 4px rgba(0, 0, 0, .2));

			&:hover {
				filter: drop-shadow(2px 12px 12px rgba(0, 0, 0, 0.2));
				transform: translate(0, -4px);
			}
		}

		@media screen and (min-width: $screen-sm) {
			transform: translate(0, -30%);
		}

		@media screen and (min-width: $screen-lg) {
			right: 4%;
		}
	}
}


.fs-i { font-style: italic;}
.fs-n { font-style: normal;}

.fw-b { font-weight: bold;}
.fw-n { font-weight: normal;}

.fw-100 { font-weight: 100 !important;}
.fw-200 { font-weight: 200 !important;}
.fw-300 { font-weight: 300 !important;}
.fw-400 { font-weight: 400 !important;}
.fw-500 { font-weight: 500 !important;}
.fw-600 { font-weight: 600 !important;}
.fw-700 { font-weight: 700 !important;}
.fw-800 { font-weight: 800 !important;}
.fw-900 { font-weight: 900 !important;}

.fw-sm-b {
	@media screen and (max-width: $screen-sm-max) {
		font-weight: bold;
	}
}

.notes { color: #999;}
// .attention { @extend .text-tertiary;}





// title-main
// ==========================================================================

.title-main-container {
	margin-bottom: $gutter;
	padding: 20px 0;
	border-bottom: 1px solid darken(gs(16), 10%);
	background: #f2f2f2;
	text-align: center;

	@media screen and (min-width: $screen-md) {
		padding: $gutter 0;
	}
}

.title-main-container-next {
	position: relative;
	background-color: #fff;

	@media screen and (min-width: $screen-md) {
		margin-top: -95px;
	}
}

.title-main-text {
	@extend .title-1-en;
	@extend .text-serif;
	margin: 0;
	padding-top: 8px;
	text-shadow: 1px 1px 0 #fff;

	small {
		display: block;
		margin-top: 4px;
		margin-bottom: 3px;
		font-weight: normal;
		font-size: 13px;
	}

	@media screen and (max-width: $screen-sm-max) {
		font-size: 21px;
	}
}


// stickies
// ==========================================================================

@mixin sticky($background: #aaa, $border-radius: 50%) {
	display: inline-block;
	margin-right: 12px;
	padding: 10px 14px;
	border-radius: $border-radius;
	background: $background;
	color: #fff;
}

.sticky-1 {
	@include sticky($color-brand-1);
}

.label-1 {
	@include sticky($color-brand-1, 2px);
	margin: 0;
	padding: 3px 10px;
}

.sticky-2 {
	@include sticky(#29abe2);
}

.label-2 {
	@include sticky(#29abe2, 2px);
	margin: 0;
	padding: 3px 10px;
}

.sticky-3 {
	@include sticky(#ff757f);
}

.label-3 {
	@include sticky(#ff757f, 2px);
	margin: 0;
	padding: 3px 10px;
}

.sticky-4 {
	@include sticky(#a696e6);
}

.label-4 {
	@include sticky(#a696e6, 2px);
	margin: 0;
	padding: 0 10px;
}

.label-member-only {
	@include sticky(#b38b1b, 2px);
	vertical-align: middle;
	padding: 6px 12px;
	font-size: 12px;
	font-weight: normal;
}


.label-extended {
	min-width: 140px;
	padding: 6px;
	text-align: center;
	font-size: 16px;
}

