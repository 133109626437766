@charset "UTF-8";

%base-caret {
	display: inline-block;
	height: 0;
	opacity: 0.8;
	vertical-align: middle;
	width: 0;
}

.caret-right {
	@extend %base-caret;
	border-bottom: 3px solid transparent;
	border-top: 3px solid transparent;
	border-left: 4px solid;
}

.caret-left {
	@extend %base-caret;
	border-bottom: 3px solid transparent;
	border-top: 3px solid transparent;
	border-right: 4px solid;
}

.caret-up {
	@extend %base-caret;
	border-left: 3px solid transparent;
	border-right: 3px solid transparent;
	border-bottom: 4px solid;
}

.caret-down {
	@extend %base-caret;
	border-left: 3px solid transparent;
	border-right: 3px solid transparent;
	border-top: 4px solid;
}


.pre-unstyled {
	display: block;
	padding: 0;
	margin: 0;
	font-size: inherit;
	line-height: inherit;
	word-break: break-all;
	word-wrap: break-word;
	color: inherit;
	background-color: transparent;
	border: none;
	border-radius: 0;
}

.whs-nw,
.whitespace-nowrap {
	white-space: nowrap;
}

.whs-n,
.whitespace-normal {
	white-space: normal;

}

.va-t,
.vertical-align-top { vertical-align: top !important;}
.va-m,
.vertical-align-middle { vertical-align: middle !important;}
.va-b,
.vertical-align-bottom { vertical-align: bottom !important;}

.ov-h,
.overflow-hidden {
	overflow: hidden;
}


// Positions
// ==========================================================================

.pos-s { position: static !important; }
.pos-a { position: absolute !important; }
.pos-r { position: relative !important; }
.pos-f { position: fixed !important; }

@media screen and (max-width: $screen-xs-max) {
	.pos-xs-s { position: static !important; }
	.pos-xs-a { position: absolute !important; }
	.pos-xs-r { position: relative !important; }
	.pos-xs-f { position: fixed !important; }
}

@media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) {
	.pos-sm-s { position: static !important; }
	.pos-sm-a { position: absolute !important; }
	.pos-sm-r { position: relative !important; }
	.pos-sm-f { position: fixed !important; }
}

@media screen and (min-width: $screen-md) and (max-width: $screen-md-max) {
	.pos-md-s { position: static !important; }
	.pos-md-a { position: absolute !important; }
	.pos-md-r { position: relative !important; }
	.pos-md-f { position: fixed !important; }
}

@media screen and (min-width: $screen-lg) {
	.pos-lg-s { position: static !important; }
	.pos-lg-a { position: absolute !important; }
	.pos-lg-r { position: relative !important; }
	.pos-lg-f { position: fixed !important; }
}

.t-0 { top: 0 !important; }
.b-0 { bottom: 0 !important; }
.r-0 { right: 0 !important; }
.l-0 { left: 0 !important; }

// Displays
// ==========================================================================

@mixin create-display-styles($screen: '') {
	@if $screen != '' {
		$screen: unquote($screen + '-');
	}
	.d-#{$screen}b, .display-#{$screen}block                    { display: block !important; }
	.d-#{$screen}i, .display-#{$screen}inline                   { display: inline !important; }
	.d-#{$screen}ib, .display-#{$screen}inline-block            { display: inline-block !important; }
	.d-#{$screen}f, .display-#{$screen}flex                     { display: flex !important; }
	.ai-#{$screen}bl, .align-items-#{$screen}baseline           { align-items: baseline; }
	.ai-#{$screen}c, .align-items-#{$screen}center              { align-items: center; }
	.ai-#{$screen}fs, .align-items-#{$screen}flex-start         { align-items: flex-start; }
	.ai-#{$screen}fe, .align-items-#{$screen}flex-end           { align-items: flex-end; }
	.jc-#{$screen}c, .justify-content-#{$screen}center          { justify-content: center; }
	.jc-#{$screen}fs, .justify-content-#{$screen}flex-start     { justify-content: flex-start; }
	.jc-#{$screen}fe, .justify-content-#{$screen}flex-end       { justify-content: flex-end; }
	.jc-#{$screen}sa, .justify-content-#{$screen}space-around   { justify-content: space-around; }
	.jc-#{$screen}sb, .justify-content-#{$screen}space-between  { justify-content: space-between; }
	.as-#{$screen}s, .align-self-#{$screen}stretch              { align-self: stretch; }
	.as-#{$screen}c, .align-self-#{$screen}center               { align-self: center; }
	.as-#{$screen}fs, .align-self-#{$screen}flex-start          { align-self: flex-start; }
	.as-#{$screen}fe, .align-self-#{$screen}flex-end            { align-self: flex-end; }
	.fxw-#{$screen}n, .flex-wrap-#{$screen}nowrap               { flex-wrap: nowrap; }
	.fxw-#{$screen}w, .flex-wrap-#{$screen}wrap                 { flex-wrap: wrap; }
	.fxd-#{$screen}r, .flex-direction-#{$screen}row             { flex-direction: row !important; }
	.fxd-#{$screen}rr, .flex-direction-#{$screen}row-reverse    { flex-direction: row-reverse !important; }
	.fxd-#{$screen}c, .flex-direction-#{$screen}column          { flex-direction: column !important; }
	.fxd-#{$screen}cr, .flex-direction-#{$screen}column-reverse { flex-direction: column-reverse !important; }
}

@include create-display-styles();

@media screen and (max-width: $screen-xs-max) {
	@include create-display-styles('xs');
}

@media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) {
	@include create-display-styles('sm');
}

@media screen and (min-width: $screen-md) and (max-width: $screen-md-max) {
	@include create-display-styles('md');
}

@media screen and (min-width: $screen-lg) {
	@include create-display-styles('lg');
}

// hack for safari, ios
.row.d-f.fxw-w:before { content: none !important; }


// Visibilities
// ==========================================================================

.visible-xxs { display: none !important; }
.hidden-xxs { display: block !important; }

@media screen and (max-width: $screen-xxs-max) {
	.visible-xxs { display: block !important; }
	.hidden-xxs { display: none !important; }
}


// clear, float
// ==========================================================================

.clear       { clear: both !important;}
.clear-right { clear: right !important;}
.clear-left  { clear: left !important;}
.clear-none  { clear: none !important;}

.clear-child-left {
	> :nth-child(odd) {
		clear: left !important;
	}
}

@media screen and (max-width: $screen-xs-max) {
	.clear-xs       { clear: both !important;}
	.clear-xs-right { clear: right !important;}
	.clear-xs-left  { clear: left !important;}
	.clear-xs-none  { clear: none !important;}
	.pull-xs-right { float: right !important;}
	.pull-xs-left  { float: left !important;}
	.pull-xs-none  { float: none !important;}
}

@media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) {
	.clear-sm       { clear: both !important;}
	.clear-sm-right { clear: right !important;}
	.clear-sm-left  { clear: left !important;}
	.clear-sm-none  { clear: none !important;}
	.pull-sm-right { float: right !important;}
	.pull-sm-left  { float: left !important;}
	.pull-sm-none  { float: none !important;}
}

@media screen and (min-width: $screen-md) and (max-width: $screen-md-max) {
	.clear-md       { clear: both !important;}
	.clear-md-right { clear: right !important;}
	.clear-md-left  { clear: left !important;}
	.clear-md-none  { clear: none !important;}
	.pull-md-right { float: right !important;}
	.pull-md-left  { float: left !important;}
	.pull-md-none  { float: none !important;}
}

@media screen and (min-width: $screen-lg) {
	.clear-lg       { clear: both !important;}
	.clear-lg-right { clear: right !important;}
	.clear-lg-left  { clear: left !important;}
	.clear-lg-none  { clear: none !important;}
	.pull-lg-right { float: right !important;}
	.pull-lg-left  { float: left !important;}
	.pull-lg-none  { float: none !important;}
}
