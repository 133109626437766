@charset "UTF-8";

.nav-1 {
	@extend .nav;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	> li {
		> a {
			@extend %base-transition;
			padding: 1px 12px;
			border-left: 9px solid #aaa;
			color: $color-font;
			
			&:hover {
				border-left-color: #ddd !important;
				background-color: transparent;
				color: #aaa;
			}
		}
	}

	@media screen and (max-width: $screen-xs-max) {
		display: block;
		padding-left: 10px;

		> li {
			width: 50%;
			float: left;
			margin-bottom: 6px;
		}
	}
}

.nav-tabs-1 {
	@extend .nav-tabs;

	> li {
		margin-bottom: 10px;

		> a {
			@extend %base-transition;
			padding: 16px 5px 13px;
			color: #bbb;
			background-color: #f6f6f6;
		}
	}

	@media screen and (min-width: $screen-sm) {
		> li {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}
