
.col-type-a {
	@extend .col-xs-12;
	@extend .col-sm-3;
	margin-bottom: 10px;

	> div {
		margin-bottom: 3px;
		padding-top: 14px;
		padding-bottom: 14px;
		background-color: $color-brand-1;
		background-position: 50%;
		-webkit-background-size: 270px 191px;
		background-size: 270px 191px;
		color: #fff;

		@media screen and (min-width: $screen-sm) {
			margin-bottom: 10px;
		}
	}
}

.col-type-a-1 > div { background-image: url(../img/common/a_1.jpg);}
.col-type-a-2 > div { background-image: url(../img/common/a_2.jpg);}
.col-type-a-3 > div { background-image: url(../img/common/a_3.jpg);}
.col-type-a-4 > div { background-image: url(../img/common/a_4.jpg);}




$grid-h: 150px;
$grid-border-width: 0;

.grid {
	display: table;
	margin-bottom: $gutter;

	.grid-inner {
		position: relative;
		display: table-cell;
		width: 100%;
		height: 100%;
		vertical-align: middle;
		text-align: center;
	}

	a {
		// border: $grid-border-width solid transparent;
		color: #444;
		// box-shadow: 0 6px 8px rgba(0, 0, 0, .3);
		transition: all .2s ease-in-out;

		&:hover {
			// border: $grid-border-width solid #fff;
			// box-shadow: 0 6px 20px rgba(0, 0, 0, .6);
			text-decoration: none;
		}
	}

	.label-1,
	.label-2,
	.label-3,
	.label-4,
	.label-5 {
		position: absolute;
		z-index: 3;
		top: 0;
		left: 15px;
		min-width: 110px;
		padding: 3px 12px;
		border-radius: 0;
		text-align: center;
	}

	.icon-new {
		position: absolute;
		z-index: 4;
		top: -$grid-border-width;
		right: -$grid-border-width;
	}

	.text-serif {
		font-size: 19px;
		font-style: italic;
		font-weight: bold;
		color: $color-font;
	}
}

.grid-h-1 { height: $grid-h * 1 + $gutter * 0;}
.grid-h-2 { height: $grid-h * 2 + $gutter * 1;}
.grid-h-3 { height: $grid-h * 3 + $gutter * 2;}
.grid-h-4 { height: $grid-h * 4 + $gutter * 3;}
.grid-w-1 { @extend .col-xs-12; @extend .col-sm-3;}
.grid-w-2 { @extend .col-xs-12; @extend .col-sm-6;}
.grid-w-3 { @extend .col-xs-12; @extend .col-sm-9;}

.grid-1x1 { @extend .grid; @extend .grid-w-1; @extend .grid-h-1; }
.grid-1x2 { @extend .grid; @extend .grid-w-1; @extend .grid-h-2; }
.grid-1x3 { @extend .grid; @extend .grid-w-1; @extend .grid-h-3; }
.grid-2x1 { @extend .grid; @extend .grid-w-2; @extend .grid-h-1; }
.grid-2x2 { @extend .grid; @extend .grid-w-2; @extend .grid-h-2; }
.grid-2x3 { @extend .grid; @extend .grid-w-2; @extend .grid-h-3; }
.grid-3x1 { @extend .grid; @extend .grid-w-3; @extend .grid-h-1; }
.grid-3x2 { @extend .grid; @extend .grid-w-3; @extend .grid-h-2; }
.grid-3x3 { @extend .grid; @extend .grid-w-3; @extend .grid-h-3; }

@media screen and (max-width: $screen-xs-max) {
	.grid-xs-h-1 { height: $grid-h * 1 + $gutter * 0;}
	.grid-xs-h-2 { height: $grid-h * 2 + $gutter * 1;}
	.grid-xs-h-3 { height: $grid-h * 3 + $gutter * 2;}
	.grid-xs-h-4 { height: $grid-h * 4 + $gutter * 3;}
}

figure.grid {
	figcaption {
		display: none;
	}
}


// ==========================================================================
// grid-plan
// ==========================================================================

.grid-1 { grid-area: grid-1; }
.grid-2 { grid-area: grid-2; }
.grid-3 { grid-area: grid-3; }

@media screen and (min-width: 600px) {
	.grid-plan-1 {
		display: grid;
		grid-template-columns: 50% 50%;
		grid-template-rows: 50% 50%;
		grid-template-areas:
			"grid-1 grid-2"
			"grid-3 grid-2";
	}
}

// ==========================================================================
// grid-images
// ==========================================================================

$grid-images: (
	photography-location,
	photography-studio,
	activity,
	tours-standard,
	tours-genuine,
	information,
	contact
);

@each $i in $grid-images {
	.grid-image[data-image=#{$i}] {
		&:after {
			background-position: 50%;
			background-image: url(../img/home/grid-#{$i}.jpg);
		}
	}
}

.grid-image {
	position: relative;
	overflow: hidden;
	background-color: darken($color-brand-1, 40%);
	-webkit-transition: color .8s ease-in;
	-moz-transition: color .8s ease-in;
	-ms-transition: color .8s ease-in;
	transition: color .8s ease-in;

	&:hover {
		color: #fff;
	
		.text-serif {
			color: #9c8ccc;
		}
	}

	&:after {
		position: absolute;
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-ms-background-size: cover;
		background-size: cover;
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		-ms-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}

	&:hover:after {
		opacity: .15;
		// -moz-transform: scale(1.05);
		// -webkit-transform: scale(1.05);
		// -ms-transform: scale(1.05);
		// transform: scale(1.05);
	}
}

.grid-image-inner {
	position: relative;
	z-index: 2;
}

.grid-image-slide {
	position: absolute;
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-ms-background-size: cover;
	background-size: cover;
}


.grid-bordered,
.grid-inner.grid-bordered {
	border: 6px solid #fff;
}


// ==========================================================================
// grid-nav
// ==========================================================================

.grid-nav-1 {
	@extend .bg-tx-1;

	.grid-nav-title {
		position: absolute;
		top: 94px;
		left: 0;
		width: 100%;

		.text-serif {
			@extend .text-1;
			font-size: 24px;
		}
	}

	.links-1 {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;

		> li {
			@extend .clearfix;
			margin: 4px 8.5px 0;
			transition: all .2s ease-in-out;

			border: 3px solid #fff;

			background-color: #fff;
			background-position: 0 0, 99% 50%;
			background-repeat: no-repeat;
			-webkit-background-size: contain, 14px 23px;
			background-size: contain, 14px 23px;

			&:nth-child(1) { background-image: url(../img/home/nav_1.jpg), url(../img/common/arrow_1.png); }
			&:nth-child(2) { background-image: url(../img/home/nav_2.jpg), url(../img/common/arrow_1.png); }
			&:nth-child(3) { background-image: url(../img/home/nav_3.jpg), url(../img/common/arrow_1.png); }
			&:nth-child(4) { background-image: url(../img/home/nav_4.jpg), url(../img/common/arrow_1.png); }

			&:hover {
				border-color: $color-brand-1;
				background-color: $color-brand-1;
				background-position: 0 0, 100% 50%;
				box-shadow: 0 6px 15px rgba(0, 0, 0, .3);
			}
		}

		a {
			padding: 8px 10px;
			background-color: transparent;

			&:hover {
				box-shadow: none;
				color: #fff;
			
				.text-serif {
					color: #9c8ccc;
				}

			}
		}
	}
}

// ==========================================================================
// grid-blog
// ==========================================================================

.grid-blog {
	@extend .grid-image;
}

.grid-blog-text {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 10px;
	background: rgba(255, 255, 255, 0.6);
	color: #000;
	font-size: 12px;
	text-align: left;
}


// ==========================================================================
// grid-table
// ==========================================================================

.grid-tables-container {
	padding-top: 16px;
	padding-bottom: 16px;
}

.grid-table {
	@extend .col-xs-6;
	@extend .col-sm-4;
	@extend .col-md-2;

	.box-table {
		margin: 3px 0;
		text-align: left;
		transition: all .2s ease-in-out;

		&:hover {
			text-decoration: none;
			background-color: #fff;
		}

		img {
			margin-right: 5px;
		}

		@media screen and (min-width: $screen-sm) {
			margin: 7px 0;
		}

		@media screen and (min-width: $screen-md) {
			margin: 12px 0;
		}
	}
}

.grid-table-cell-image {
	@extend .box-table-cell;
	padding-right: 4px;
	width: 39px + 4px;

	img {
		@extend .img-responsive;
	}

	& + .box-table-cell {
		padding-right: 6px;
	}

	@media screen and (min-width: $screen-sm) {
		padding-right: 6px;
		width: 54px + 6px;
	}
}
