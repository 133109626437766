@charset "UTF-8";

.pswp__bg {
	background-color: rgba(#000, .7);
}

// .pswp__top-bar {
// 	background-color: transparent;
// }

// .pswp__caption {
// 	background-color: transparent;
// 	background-color: #fff;
// }

.pswp__caption {
	z-index: 1501;
	line-height: 1.4;
	font-size: 11px;
	color: #fff;

	&__center {
		text-align: center;
	}
}

.pswp__counter,
.pswp__button {
	opacity: 1;
}

.pswp__img {
	padding: 0 20px 70px;
}

// .pswp__item {
// 	margin: 0 20px;
// }

// .pswp {
// 	img {
// 		max-width: 100% !important;
// 		height: auto !important;
// 	}
// }

.pswp-help-container {
	position: fixed;
	z-index: 9999;
	top: 50%;
	left: 50%;
	width: 100px;
	height: 100px;
	margin: -50px 0 0 -50px;
	background: #f00;
}


.photoswipe-container {
	figcaption {
		display: none;
	}
}


#photoswipe-modal-placeholder {
	position: absolute;
	z-index: 9999;
}

.photoswipe-caption-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 11px;
}

.photoswipe-texts {
	color: #e9e9e9;
	font-size: min(4.2vw, 1.7rem);

	.item-num {
		display: inline-block;
		margin-bottom: 3px;
		font-size: 12px;
		color: #fff;
	}
}

.photoswipe-btn-container {
	width: 100%;
	margin-bottom: 11px;
	text-align: center;

	.photoswipe-texts + & {
		margin-top: 11px;
	}

	a[class^="btn-"] {
		width: 100%;
		min-width: 0;
	}
}


.btn-photoswipe {
	max-width: 90%;
	margin-bottom: 20px;
}


.btn-photoswipe-base {
	position: absolute;
	bottom: 0;
	left: 50%;
	z-index: 9999;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	-o-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.btn-photoswipe-hidden {
	opacity: 0;
}

.btn-photoswipe-fake {
	visibility: hidden;
	margin-bottom: 20px;
}

@media screen and (min-width: $screen-md) {
	.photoswipe-caption-container {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 20px;

		&::before {
			content: '';
			display: block;
			width: 240px;
		}
	}

	.photoswipe-texts {

		.item-num {
			font-size: 14px;
		}
	}

	.photoswipe-btn-container {
		width: auto;
		margin-bottom: 0;

		a[class^="btn-"] {
			margin-left: 20px;
			width: 240px;
		}
	}
}
