@charset "utf-8";

// ==========================================================================
// colors
// ==========================================================================
$colors: 
	#1a1f2d // 1
	#525254 // 2
	#87868a // 3
	#f2f2f2 // 4
	#eee // 5
	#dba067 // 6
	#ddb56d // 7
;

$glayscales:
	#000
	#111
	#222
	#333
	#444
	#555
	#666
	#777
	#888
	#999
	#aaa
	#bbb
	#ccc
	#ddd
	#eee
	#fff
;

@function c($n) {
	@return nth($colors, $n);
}

@function gs($n) {
	@return nth($glayscales, $n);
}


$color-brand-1: c(1);
$color-brand-2: c(2);
$color-brand-3: c(3);
$color-brand-4: c(4);

$color-body         : #fff;
$color-main         : $color-brand-1;
$color-sub          : $color-brand-3;
$color-point        : $color-brand-2;
$color-accent       : #d20000;
$color-accent-2     : #e75703;
$color-extra        : c(3);
$color-font         : #333;
$color-hover        : #999;
$color-em           : #666;
$color-strong       : inherit;
$color-ins          : #c03;
$color-mark         : #5b5905;
$color-border       : #ccc;
$color-border-dotted: #aaa;

$color-bg-0         : #fff;

$color-1: #f7931e;
$color-2: #549e00;
$color-3: #29abe2;

$color-border-dotted: #b0a89f;
$color-border-1: #b2b2b2;
$color-border-2: #e6e6e6;
$color-border-3: #ddd;
$color-border-4: rgba(#000, .1);

$brand-base:              $color-main;
$brand-darker:            lighten($brand-base, 13.5%);
$brand-dark:              lighten($brand-base, 20%);
$brand:                   lighten($brand-base, 33.5%);
$brand-light:             lighten($brand-base, 46.7%);
$brand-lighter:           lighten($brand-base, 93.5%);

$color-twitter: #55acee;
$color-facebook: #3a548b;
$color-instagram: #8c453a;

// ==========================================================================
// misc
// ==========================================================================

$dir: "..";
$dir-desktop: "../..";

$screen-xxs-max: 375px;
// $screen-xxs-max: 356px;

$logo-width: 130px;

$gutter: 30px;
$gutter-md: 60px;

$border-radius: 4px;

$content-max-width: 1340px;

%base-transition {
	-webkit-transition: .2s linear;
	transition: .2s linear;
}

// ==========================================================================
// bootstrap
// ==========================================================================

$icon-font-path: "../lib/bootstrap/fonts/bootstrap/";
$font-family-base: "Noto Sans JP", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
$font-family-serif-base: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "IPAex明朝", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
$font-size-base: 14px;

$grid-gutter-width: 30px;

$nav-tabs-border-color: $color-border-1;

