.slide-container {
	@extend .box;

	.slide {
		margin: 0 25px;
		width: 296px;

		img {
			margin: 0 auto;
		}
	}
}

$prev-width: 21px;
$prev-height: 54px;

.slick-prev,
.slick-next,
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
	width: $prev-width;
	height: $prev-height;
	margin-top: $prev-height * -.5;
	background-position: 50%;
	background-repeat: no-repeat;
	-webkit-background-size: contain;
	background-size: contain;

	&:before {
		content: none;
	}

	.slide-nav & {
		width: $prev-width * .5;
		height: $prev-height * .5;
		margin-top: $prev-height * -.5 * .5;
	}
}

.slick-prev {
	left: 3%;
	background-image: url(../img/common/icon_prev_alpha.png);
}
.slick-next {
	right: 3%;
	background-image: url(../img/common/icon_next_alpha.png);
}

.slick-prev:hover,
.slick-prev:focus {
	opacity: .8;
	background-image: url(../img/common/icon_prev_alpha.png);
}
.slick-next:hover,
.slick-next:focus {
	opacity: .8;
	background-image: url(../img/common/icon_next_alpha.png);
}


.slide-caption {
	margin-top: 5px;
	text-align: center;
}


.slide-container-1 {
	@extend .box;
	margin-right: auto;
	margin-left: auto;
	background: url(../search/img/blockmaps_bg.jpg) no-repeat 50% 0;
	background-size: cover;

	@media screen and (min-width: $screen-md) {
		background-size: initial;
	}

	.slide {
		@extend .clearfix;
		margin-top: 30px;

		img {
			margin: 0 auto;
			background: rgba(255, 255, 255, .6);
		}
	}

	.slide-caption {
		margin-top: 20px;
		padding-top: 10px;
		background-color: #fff;

		@media screen and (min-width: $screen-md) {
			margin-top: 30px;
		}
	}
}


.slide-nav {
	.slide {
		@extend %base-transition;
		width: 210px;
		height: 120px;
		cursor: pointer;
		
		&:hover {
			opacity: .75;
		}

		> div {
			width: 95%;
			height: 120px;
			margin: 0 auto;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			-webkit-background-size: cover;
			background-size: cover;
			background-color: rgba(255, 255, 255, .7);
		}
	}
}

.slide-nav-1 {
	@extend .slide-nav;
	margin-right: auto;
	margin-left: auto;
	padding: 10px 0;
	background-color: #ececec;
}