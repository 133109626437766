// ==========================================================================
// images
// ==========================================================================

.img-fullsize-container {
	margin-left: $grid-gutter-width * -.5;
	margin-right: $grid-gutter-width * -.5;
}

@media screen and (min-width: $screen-lg) {
	.img-lg-trim-center {
		position: relative;
		overflow: hidden;

		img {
			position: relative;
			transform: translate(-50%, -50%);
			top: 50%;
			left: 50%;
		}
	}
}

// .lazyload,
.lazyloading {
	opacity: 0;
}
.lazyloaded {
	opacity: 1;
	transition: opacity .5s;
	// animation: anim-lazyloaded .9s ease .5s backwards;
}


@for $i from 0 through 50 {
	.delay-#{$i} { animation-delay: #{$i * .1}s; }
}


@keyframes anim-lazyloaded {
	0% {
		opacity: 0;
		transform: translateY(50px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.img-overlay-1 {
	position: relative;
	display: block;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .6);
		@extend %base-transition;
	}

	a:hover &:after {
		background-color: rgba(0, 0, 0, .2);
	}
}

.img-ib,
img.img-ib {
	display: inline-block;
}

.img-responsive {
	display: inline-block;
}

.img-thumbnail-1 {
	@extend .img-thumbnail;
	padding: 3px;
	border-radius: 0;
}

.img-circle-1 {
	@extend .img-circle;
	padding: 3px;
	background-color: #fff;
	box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
}

.img-hover {
	@extend %base-transition;
	backface-visibility: hidden;
	
	a:hover & {
		opacity: .75;
	}
}

.img-hover-040 {
	@extend .img-hover;
	
	a:hover & {
		opacity: .40;
	}
}

.img-hover-020 {
	@extend .img-hover;
	
	a:hover & {
		opacity: .20;
	}
}

.img-pull-right {
	float: right;
	margin-left: $gutter * .5;
	margin-bottom: $gutter * .5;

	@media screen and (min-width: $screen-sm) {
		margin-left: $gutter;
		margin-bottom: $gutter;
	}
}

.img-pull-right-50p {
	@extend .img-pull-right;
	@extend .img-xs-responsive-50;
}

.img-pull-left {
	float: left;
	margin-right: $gutter * .5;
	margin-bottom: $gutter * .5;

	@media screen and (min-width: $screen-sm) {
		margin-right: $gutter;
		margin-bottom: $gutter;
	}
}

.img-pull-left-50p {
	@extend .img-pull-left;
	@extend .img-xs-responsive-50;
}

@media screen and (max-width: $screen-xs-max) {
	.img-xs-responsive-50 {
		display: block;
		max-width: 50%;
		height: auto;
	}
	.img-xs-responsive-40 {
		display: block;
		max-width: 40%;
		height: auto;
	}
	.img-xs-responsive-30 {
		display: block;
		max-width: 30%;
		height: auto;
	}
	.img-xs-responsive-20 {
		display: block;
		max-width: 20%;
		height: auto;
	}

	.img-xs-pull-n {
		float: none;
	}

	.img-retina {
		max-width: 50%;
		height: auto;
	}
}

// ==========================================================================
// img-main
// ==========================================================================
.img-main {
	@extend %base-transition;	    
	width: 100%;
	height: 400px;
	background-repeat: no-repeat;
	background-position: 50%;
	-webkit-background-size: cover;
	background-size: cover;

	@media screen and (min-width: $screen-sm) {
		height: 200px;
	}

	@media screen and (min-width: $screen-md) {
		height: 400px;
	}
}
