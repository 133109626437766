// ==========================================================================
// forms
// ==========================================================================


label,
input[type="radio"],
input[type="checkbox"] {
	vertical-align: middle;
}

input[type="radio"],
input[type="checkbox"] {
	margin-top: 0;
	margin-right: 3px;
}

label {
	cursor: pointer;
	font-weight: normal;
	th & {
		margin-bottom: 0;
		font-weight: bold;
	}

	&.validate-error {
		display: block;
		font-weight: bold;
		color: $color-accent;
	}
}

.form-wrap-input {
	display: inline-block;
	margin-right: 15px;
}

.form-group {
	& + .form-group {
		border-top: 1px dotted #bbb;
		padding-top: 15px;
	}
}

$form-control-margin-right: 3px;

.form-control {
	display: inline-block;
	height: auto;
	margin-top: 3px;
	margin-bottom: 3px;
	padding: 6px;
	border-radius: 2px;
	border-color: #999;
	background-color: #f9f9f9;
	-webkit-box-shadow: none;
	box-shadow: none;

}

select.form-control {
	width: auto;
	margin-right: $form-control-margin-right;
	padding: 2px 6px;
}

input[type=date] {
	display: inline-block;
	width: 160px;
}
input[type=time] {
	display: inline-block;
	width: 98px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	input[type=date],
	input[type=time],
	input[type=datetime-local],
	input[type=month] {
		line-height: 1.2;
	}
}

.checkbox-inline,
.radio-inline {
	margin-right: 10px;
}

.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
	margin-left: 0;
}

.label-required,
.label-optional {
	padding: 0 6px;
	border-radius: 2px;
	font-size: 11px;
	font-weight: bold;
}

.label-required {
	color: #e83333;
}

.label-optional {
	color: #999;
}
