.separator-1 {
	& + .separator-1 {
		margin-top: $gutter;
		padding-top: $gutter;
		border-top: 1px dotted #b2b2b2;
	}
}


.separator-top {
	margin-top: $gutter;
	padding-top: $gutter;
	border-top: 1px solid $color-border-1;

	@media screen and (min-width: $screen-md) {
		margin-top: $gutter-md;
	}
}

.separator-top-dotted {
	@extend .separator-top;
	border-top-style: dotted;
	border-top-color: #b2b2b2;
}

.separator-bottom {
	margin-bottom: $gutter;
	padding-bottom: $gutter;
	border-bottom: 1px solid $color-border-1;

	@media screen and (min-width: $screen-md) {
		margin-bottom: $gutter-md;
	}
}

.separator-bottom-dotted {
	@extend .separator-bottom;
	border-bottom-style: dotted;
	border-bottom-color: #b2b2b2;
}

