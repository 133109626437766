@charset "UTF-8";

.blog-entry-heading {}

.blog-archive-container {
	@extend .clearfix;
	@extend .separator-bottom;
	padding-bottom: $gutter;

	@media screen and (min-width: $screen-md-max) {
		margin-bottom: $gutter;
	}

	.blog-entry-title {
		margin-bottom: 8px;
		font-size: 18px;

		@media screen and (min-width: $screen-md-max) {
			font-size: 21px;
		}
	}
}

.blog-entry-heading {
	margin-top: 12px;
}

.blog-entry-body {
	@extend .g-b;
}

.blog-entry-title {
	@extend .title-1;
	margin: 0 0 30px;
	font-weight: bold;
	text-align: left;

	font-size: 18px;

	@media screen and (min-width: $screen-sm) {
		font-size: 19px;
	}

	@media screen and (min-width: $screen-md) {
		font-size: 20px;
	}

	a {
		color: #603813;

		&:hover {
			color: inherit;
		}
	}

	.blog-date {
		display: inline-block;
		margin-bottom: 8px;
		font-size: 14px;
	}
}

.blog-entry-category {
	font-size: 12px;
}


.blog-entry-body {
	@extend .clearfix;

	> h1 {
		margin-top: $gutter;
		padding-top: $gutter;
		border-top: 1px dotted #aaa;
		font-size: 21px;
		font-weight: bold;
		color: $color-brand-3;
	}

	> h2 {
		font-size: 18px;
		font-weight: bold;
		color: $color-brand-2;
	}

	> h3 {
		margin-top: $gutter * 2;
		font-size: 21px;
		font-weight: bold;
	}

	p {
		margin-bottom: $gutter;
		font-size: 16px;
		line-height: 1.85;

		> img {
			@extend .img-responsive;
			display: inline-block;
		}
	}

	table {
		@extend .table-2;
	}


	.aligncenter {
		margin-right: auto;
		margin-left: auto;
	}

	img.aligncenter {
		display: block;
	}

	.alignright {
		float: right;
		text-align: right;
	}

	img.alignright {
		margin-left: 20px;
	}

	.alignleft {
		float: left;
		text-align: left;
	}

	img.alignleft {
		margin-right: 20px;
	}

	.wp-caption {
		max-width: 100%;

		> img {
			@extend .img-responsive;
			display: inline-block;
		}
	}

	.wp-caption-text {
		margin-top: 3px;
		font-size: 12px;
		color: #777;
	}
}

.blog-entry-eyecatch {
	margin-bottom: 30px;

	img {
		@extend .img-responsive;
	}
}


.blog-entry-thumbnail {
	float: left;
	width: 82px;
	margin-right: $gutter * .5;

	@media screen and (min-width: $screen-md-max) {
		width: 112px;
		margin-right: $gutter;
	}

	img {
		@extend .img-responsive;
	}
}

// ==========================================================================
// pagination
// ==========================================================================

.navigation {
	display: block;
	text-align: center;

	.screen-reader-text {
		display: none;
	}
}

.nav-links {
	display: inline-block;
	padding-left: 0;
	margin: $line-height-computed auto;
	border-radius: $border-radius-base;

	> a,
	> span {
		position: relative;
		float: left; // Collapse white-space
		padding: 4px 9px;
		line-height: $line-height-base;
		text-decoration: none;
		color: #666;
		background-color: $pagination-bg;
		border: 1px solid $pagination-border;
		margin-left: -1px;

		@media screen and (min-width: $screen-md-max) {
			padding: 14px 24px;
		}
	}

	> a:first-child,
	> span:first-child {
		margin-left: 0;
		@include border-left-radius($border-radius-base);
	}

	> a:last-child,
	> span:last-child {
		@include border-right-radius($border-radius-base);
	}

	> a,
	> span {
		&:hover,
		&:focus {
			z-index: 2;
			color: #aaa;
			background-color: $pagination-hover-bg;
			border-color: $pagination-hover-border;
		}
	}

	> .current {
		&,
		&:hover,
		&:focus {
			z-index: 3;
			color: $pagination-active-color;
			background-color: #696153;
			border-color: #696153;
			cursor: default;
		}
	}

	> .disabled,
	> .disabled:hover,
	> .disabled:focus {
		color: $pagination-disabled-color;
		background-color: $pagination-disabled-bg;
		border-color: $pagination-disabled-border;
		cursor: $cursor-disabled;
	}
}


// ==========================================================================
// side bar
// ==========================================================================

.blog-sidebar {
	margin-top: 21px;
	margin-bottom: 21px;

	.blog-archives {
		> h3 {
			margin-bottom: 2px;
			padding-bottom: 12px;
			border-bottom: 2px solid $color-main;
			font-size: 16px;
			font-weight: bold;
		}
	}
}

.blog-archives-list,
.blog-archives-list .children {
	@extend .list-unstyled;

	> li {
		> a {
			display: block;
			padding: 8px 0;
			border-bottom: 1px dotted #aaa;
		}
	}
}

.blog-archives-list .children {

	> li {
		> a {
			padding-left: 12px;
			background: url(../img/common/arrow_3.png) no-repeat 2px 50%;
			background-size: 4px 5px;

			&:hover {
				background-image: url(../img/common/arrow_2.png);
			}
		}
	}

	.children {
		> li {
			> a {
				padding-left: 24px;
			}
		}
	}
}
