@charset "UTF-8";

$global-nav-length: 5;
$global-nav-button-width: 100% / $global-nav-length;

.global-nav {
	> ul {
		@extend .nav;
	}
}

@media screen and (max-width: $screen-sm-max) {
	.global-nav {
		display: none;
	}
}

@media screen and (min-width: $screen-md) {
	.global-nav {
		position: absolute;
		top: 27px;
		right: $grid-gutter-width * .5;

		> ul {
			float: left;
			margin: 0 auto;
			text-align: center;

			> li {
				display: inline;
				float: left;

				> a {
					display: block;
					padding: 7px 15px 0;
					color: #333;
					text-align: center;
					font-size: 14px;

					transition: all .3s linear;

					// &:before {
					// 	display: block;
					// 	content: attr(data-en);
					// 	font-size: 14px;
					// 	color: $color-font;
					// }

					&:after {
						content: '';
						display: block;
						width: 0;
						height: 2px;
						margin-top: 7px;
						background-color: transparent;
						transition: all .3s ease-in-out;
					}

					&:hover,
					&:focus {
						background-color: #f7f7f7;
						color: #bbb;

						// &:before {
						// 	color: $color-main;
						// }

						&:after {
							// width: 100%;
							// background-color: c(1);
						}
					}
				}

				> .underconstruction,
				> .underconstruction:hover,
				> .underconstruction:after,
				> .underconstruction:hover:after {
					// cursor: default;
					// background-color: transparent;
					// box-shadow: none;
					// color: #ccc;
				}

				> ul {
					position: absolute;
					z-index: 99;
					display: none;
					opacity: 0;
					min-width: 210px;
					margin-top: -10px;
					padding: 10px;
					background-color: #fafafa;
					box-shadow: 0 3px 6px rgba(0, 0, 0, .4);
					transition: all .2s ease-in-out;
				}

				&:hover > ul {
					display: block;
					opacity: 1;
					margin-top: 0;
					-webkit-animation-duration: 0.4s;
					        animation-duration: 0.4s;
					-webkit-animation-name: dropdown-fade-in;
					        animation-name: dropdown-fade-in;
				}

				> ul {
					background-color: c(1);

					li {
						width: 100%;
					}

					a {
						display: block;
						padding: 10px 15px;
						border-top: 1px solid rgba(lighten(c(1), 50%), .2);
						font-size: 13px;
						color: lighten(c(1), 50%);
						text-align: left;
						-webkit-transition: all .2s linear;
						-moz-transition: all .2s linear;
						-ms-transition: all .2s linear;
						-o-transition: all .2s linear;
						transition: all .2s linear;

						&:first-child {
							border-top: none;
						}

						&:hover {
							background-color: lighten(c(1), 10%);
							text-decoration: none;
						}
					}
				}
			}
		}

		[class^="btn-"] {
			min-width: 10px;
			padding: 6px 30px;
			font-size: 13px;
		}

		> ul + [class^="btn-"] {
			margin-left: 15px;
		}
	}
}

@media screen and (min-width: $screen-lg) {
	.global-nav {
		> ul {

			> li {
				> a {
					padding-left: 20px;
					padding-right: 20px;
				}
			}
		}

		> ul + [class^="btn-"] {
			margin-left: 20px;
		}
	}
}


.global-nav-sp {
	position: fixed;
	z-index: 99;
	top: $header-height;
	right: -150%;
	overflow: hidden;
	max-width: 500px;
	width: 100%;
	margin: 0;
	padding: 0;
	background: #fff;
	box-shadow: 0 6px 23px rgba(0, 0, 0, .15);
	transition: all .4s;

	&.show {
		right: 0;
	}

	&:before {
		content: 'MENU';
		display: block;
		margin-top: 20px;
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid c(1);
		font-size: 14px;
		font-weight: bold;
		text-align: center;
		color: c(1);
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	> ul {
		margin: 20px;

		li {
			border-bottom: 1px solid #ddd;
		}

		a {
			display: block;
			padding: 10px 15px;
			color: #333;
			overflow: hidden;
			white-space: nowrap;
		}
	}
}

.global-nav-sp-banners {
	margin: 30px 20px;
	text-align: center;

	a[class^="btn-"] {
		width: 100%;
	}
}


@-webkit-keyframes dropdown-fade-in {
	0% {
		display: none;
		opacity: 0;
		margin-top: -10px;
	}

	1% {
		display: block;
		opacity: 0;
		margin-top: -10px;
	}

	100% {
		display: block;
		opacity: 1;
		margin-top: 0;
	}
}

@keyframes dropdown-fade-in {
	0% {
		display: none;
		opacity: 0;
		margin-top: -10px;
	}

	1% {
		display: block;
		opacity: 0;
		margin-top: -10px;
	}

	100% {
		display: block;
		opacity: 1;
		margin-top: 0;
	}
}
