@charset "UTF-8";

@mixin closs($color, $size, $width: 1px) {
	width: $size;
	height: $size;
	transform: rotate(45deg);
	$translate: $size * .5 - floor($width * .5);

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 0;
		border-top: $width solid $color;
	}

	&:before {
		transform: translate(0, $translate);
	}

	&:after {
		transform: translate(0, $translate) rotate(90deg);
	}
}
