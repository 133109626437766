@charset "UTF-8";
// http://www.nxworld.net/tips/12-css-hamburger-menu-active-effect.html

.menu-trigger,
.menu-trigger span {
	display: inline-block;
	transition: all .4s;
	box-sizing: border-box;
}

.menu-trigger {
	position: relative;
	width: 20px;
	height: 18px;
}

.menu-trigger span {
	position: absolute;
	left: 0;
	width: 100%;
	height: 2px;
	background-color: #666;
	border-radius: 2px;
}

.menu-trigger span:nth-of-type(1) {
	top: 0;
}
.menu-trigger span:nth-of-type(2) {
	top: 8px;
}
.menu-trigger span:nth-of-type(3) {
	bottom: 0;
}

// #5

.menu-trigger.active span:nth-of-type(1) {
	-webkit-transform: translateY(8px) rotate(-45deg);
	transform: translateY(8px) rotate(-45deg);
}

.menu-trigger.active span:nth-of-type(2) {
	left: 200%;
	opacity: 0;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	-webkit-animation: active-menu-bar02 .8s forwards;
	animation: active-menu-bar02 .8s forwards;
}

@-webkit-keyframes active-menu-bar02 {
	100% {
		height: 0;
	}
}
@keyframes active-menu-bar02 {
	100% {
		height: 0;
	}
}

.menu-trigger.active span:nth-of-type(3) {
	-webkit-transform: translateY(-8px) rotate(45deg);
	transform: translateY(-8px) rotate(45deg);
}

// ※上記では消えていく中央ラインがクリックできるのを防ぐためにspan:nth-of-type(2)の動きの一部にanimationを用いていますが、Chromeであればanimationを使用せずにz-index: -1を記述すれば同じ見た目を実装できます。
