@charset "UTF-8";

.bg-extend-1 {
	max-width: $content-max-width;
	margin-left: auto;
	margin-right: auto;
	padding: $gutter * .5 0;
	background-color: #fff;

	& + & {
		@extend .border-t-1;
		@extend .border-gs-14;
	}

	@media screen and (min-width: $screen-sm) {
		padding: $gutter 0;
	}
}

.bg-photo-1 {
	background: url(../img/common/bg_photo_1.jpg) 50% 50%;
	-webkit-background-size: cover;
	background-size: cover;
}

.bg-tx-1 {
	background: url(../img/common/texture_1.png);
	-webkit-background-size: 20px;
	background-size: 20px;
}

.bg-tx-2 {
	background: url(../img/common/texture_2.png);
	-webkit-background-size: 20px;
	background-size: 20px;
}

.bg-tx-3 {
	background: url(../img/common/texture_3.png);
	-webkit-background-size: 20px;
	background-size: 20px;
}


.bg-0 { background-color: #fff; }

@for $i from 1 through length($colors) {
	.bg-#{$i} {
		background-color: c($i);
	}
}

@for $i from 1 through length($glayscales) {
	.bg-gs-#{$i} {
		background-color: gs($i);
	}
}

.bgz-init { background-size: initial !important; }
.bgz-cover { background-size: cover !important; }
.bgz-contain { background-size: contain !important; }
.bg-no-repeat { background-repeat: no-repeat !important; }

