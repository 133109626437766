// ==========================================================================
// footer
// ==========================================================================

$footer-border-color: rgba(c(2), .3);

.footer {
	padding-top: 30px;
	background-color: #e5e5e5;

	> .container:first-child {
		margin-bottom: 30px;
	}

	@media screen and (min-width: $screen-md) {
		padding-top: 60px;
		border-top: 1px solid $footer-border-color;

		> .container:first-child {
			margin-bottom: 0;
		}
	}
}

.footer-logo {
	display: block;
	width: $logo-width;
	margin-right: auto;
	margin-left: auto;

	img {
		display: inline-block;
		max-width: 100%;
		height: auto;
	}

	// @media screen and (min-width: $screen-md) {
	// 	margin-right: 0;
	// 	margin-left: 0;
	// }
}


.footer-sns-links {
	margin-top: 30px;

	a[class^="btn-"] {
		@extend .btn-4;
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 220px;
		min-width: 0;
		width: 100%;
		margin: 10px auto;
		font-weight: normal !important;
	}

	.btn-instagram {
		background-color: #fff;
		&::before {
			content: '';
			display: inline-block;
			width: 16px;
			height: 16px;
			margin-right: 5px;
			background-image: url(#{$dir}/img/common/Instagram-v051916.png);
			background-size: contain;
		}
	}
}


.footer-links {

	ul {
		list-style: none;
		margin-bottom: 0;
		padding-left: 0;
		
		li {
		}

		.placeholder,
		a {
			display: block;
			padding: 11px 15px;
			border-bottom: 1px solid $footer-border-color;
			color: #333;
			text-align: left;
			font-size: 12px;
		}

		a {
			@extend %base-transition;
			&:hover {
				text-decoration: none;
				background-color: #eee;
				color: #aaa;
			}
		}

		.placeholder {
			@extend .hidden-md;
			@extend .hidden-lg;
		}
	}

	[class^="btn-"] {
		width: 100%;
		margin-bottom: 15px;
		padding-top: 9px;
		padding-bottom: 9px;
	}

	@media screen and (max-width: $screen-sm-max) {
		.footer & {
			> ul:first-child {
				border-top: 1px solid $footer-border-color;
			}
		}
	}

	@media screen and (min-width: $screen-md) {
		li:first-child a,
		.footer-links-course li:nth-child(2) a {
			border-top: 1px solid $footer-border-color;
		}
	}
}

.footer-links-divider {
	@media screen and (max-width: $screen-sm-max) {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			display: block;
			width: 1px;
			height: 100%;
			border-left: 1px solid $footer-border-color;
		}

		.row {
			margin-right: 0;
			margin-left: 0;

			[class^="col-xs-"] {
				padding-right: 0;
				padding-left: 0;
			}
		}
	}
}

.footer-links-course {
	@media screen and (max-width: $screen-sm-max) {
		background-color: c(4);
	}
}

.copyright {
	@extend .bg-1;
	clear: both;
	padding: 28px 0;
	color: desaturate(lighten(c(1), 40%), 25%);
	text-align: center;
	font-size: 12px;

	@media screen and (max-width: $screen-sm-max) {
		padding: 14px 0;
		font-size: 10px;
	}
}
