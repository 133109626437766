@charset "UTF-8";

.home-header {
	opacity: 0;
	top: -100px;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	-ms-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

.contents-fixed .home-header {
	opacity: 1;
	top: 0;
}


.home-main-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	text-align: center;

	// @media screen and (min-width: $screen-md) {
	// 	width: 100%;
	// 	height: 960px;
	// 	background: url(#{$dir}/img/home/main/1.jpg) no-repeat 50% 50%;
	// 	background-size: cover;
	// }
}

.home-main-logo {
	$width: $logo-width;

	position: absolute;
	z-index: 10;
	top: 20px;
	left: 20px;
	width: $width;
	margin: 0;
	font-size: 12px;

	.body-loaded & {
		animation: anim-logo-fadein 1.4s ease .5s backwards;
	}

	img {
		width: 100%;
		height: auto;
	}

	@media screen and (min-width: $screen-md) {
		width: $width;
	}
}

@keyframes anim-logo-fadein {
	0% {
		opacity: 0;
		// transform: translateY(50px);
	}
	100% {
		opacity: 1;
		// transform: translateY(0);
	}
}

.home-main-slide {
	position: relative;
	width: 100%;
	height: 100%;

	.slick-list,
	.slick-track {
		width: 100%;
		height: 100%;
	}

	.slide {
		width: 100%;
		height: 100%;
		background-position: 50% 50%;
		-webkit-background-size: cover;
		background-size: cover;
	}

	.slide-1 { background-image: url(#{$dir}/img/home/main/1.jpg); background-position: 100% 50%; }
	.slide-2 { background-image: url(#{$dir}/img/home/main/2.jpg); background-position: 50% 50%; }

	.slide-1st {
		opacity: 0;
		-webkit-transition: opacity 1.8s linear;
		-o-transition: opacity 1.8s linear;
		transition: opacity 1.8s linear;
	}

	&.slick-initialized {
		.slide-1st {
			animation: fadein 1.9s .8s both;
		}
	}
}


.home-news-contents {
	> .box {
		&:nth-child(even) {
			clear: left;
		}
	}
}
