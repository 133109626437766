@charset "UTF-8";

.border-1 {
	border: 1px solid $color-border-1;
}

.border-2 {
	border: 1px solid $color-border-2;
}

.border-3 {
	border: 1px solid #fff;
}

%border-d {
	border-top: 1px dotted $color-border-dotted;
}

.border-t-d {
	border-top: 1px dotted $color-border-dotted;
}

.border-b-d {
	border-bottom: 1px dotted $color-border-dotted;
}

.border-t-1 { border-top: 1px solid $color-border-1; }
.border-b-1 { border-bottom: 1px solid $color-border-1; }
.border-r-1 { border-right: 1px solid $color-border-1; }
.border-l-1 { border-left: 1px solid $color-border-1; }
.border-t-2 { border-top: 1px solid $color-border-2; }
.border-b-2 { border-bottom: 1px solid $color-border-2; }
.border-r-2 { border-right: 1px solid $color-border-2; }
.border-l-2 { border-left: 1px solid $color-border-2; }
.border-n   { border: none; }
.border-t-n { border-top: none; }
.border-b-n { border-bottom: none; }
.border-r-n { border-right: none; }
.border-l-n { border-left: none; }

@media screen and (max-width: $screen-xs-max) {
	.border-xs-t-1 { border-top: 1px solid $color-border-1; }
	.border-xs-b-1 { border-bottom: 1px solid $color-border-1; }
	.border-xs-r-1 { border-right: 1px solid $color-border-1; }
	.border-xs-l-1 { border-left: 1px solid $color-border-1; }
	.border-xs-n   { border: none; }
	.border-xs-t-n { border-top: none; }
	.border-xs-b-n { border-bottom: none; }
	.border-xs-r-n { border-right: none; }
	.border-xs-l-n { border-left: none; }
}

@media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) {
	.border-sm-t-1 { border-top: 1px solid $color-border-1; }
	.border-sm-b-1 { border-bottom: 1px solid $color-border-1; }
	.border-sm-r-1 { border-right: 1px solid $color-border-1; }
	.border-sm-l-1 { border-left: 1px solid $color-border-1; }
	.border-sm-n   { border: none; }
	.border-sm-t-n { border-top: none; }
	.border-sm-b-n { border-bottom: none; }
	.border-sm-r-n { border-right: none; }
	.border-sm-l-n { border-left: none; }
}

@media screen and (min-width: $screen-md) and (max-width: $screen-md-max) {
	.border-md-t-1 { border-top: 1px solid $color-border-1; }
	.border-md-b-1 { border-bottom: 1px solid $color-border-1; }
	.border-md-r-1 { border-right: 1px solid $color-border-1; }
	.border-md-l-1 { border-left: 1px solid $color-border-1; }
	.border-md-n   { border: none; }
	.border-md-t-n { border-top: none; }
	.border-md-b-n { border-bottom: none; }
	.border-md-r-n { border-right: none; }
	.border-md-l-n { border-left: none; }
}

@media screen and (min-width: $screen-lg) {
	.border-lg-t-1 { border-top: 1px solid $color-border-1; }
	.border-lg-b-1 { border-bottom: 1px solid $color-border-1; }
	.border-lg-r-1 { border-right: 1px solid $color-border-1; }
	.border-lg-l-1 { border-left: 1px solid $color-border-1; }
	.border-lg-n   { border: none; }
	.border-lg-t-n { border-top: none; }
	.border-lg-b-n { border-bottom: none; }
	.border-lg-r-n { border-right: none; }
	.border-lg-l-n { border-left: none; }
}

.border-c-1 {
	border-color: rgba(#000, .15);
}

@for $i from 1 through 20 {
	.border-w-#{$i} { border-width: #{$i}px !important; }
}

@for $i from 1 through length($colors) {
	.border-c-#{$i} {
		border-color: c($i) !important;
	}
	.border-t-c-#{$i} {
		border-top-color: c($i) !important;
	}
	.border-b-c-#{$i} {
		border-bottom-color: c($i) !important;
	}
	.border-r-c-#{$i} {
		border-right-color: c($i) !important;
	}
	.border-l-c-#{$i} {
		border-left-color: c($i) !important;
	}
}

@for $i from 1 through length($glayscales) {
	.border-gs-#{$i} {
		border-color: gs($i) !important;
	}
	.border-t-gs-#{$i} {
		border-top-color: gs($i) !important;
	}
	.border-b-gs-#{$i} {
		border-bottom-color: gs($i) !important;
	}
	.border-r-gs-#{$i} {
		border-right-color: gs($i) !important;
	}
	.border-l-gs-#{$i} {
		border-left-color: gs($i) !important;
	}
}

